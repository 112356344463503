import React, { useState } from "react";
import NavBarDetails from "../../Component/NavBarDetails/NavBarDetails";
import { useLocation } from "react-router-dom";
import "./ProductDetails.css";
import { Image } from "primereact/image";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../Store/cart";
import { InputTextarea } from "primereact/inputtextarea";
import { FloatLabel } from "primereact/floatlabel";
import { API } from "../../Config/Api";

const ProductDetails = () => {
  const location = useLocation();
  console.log(location.state.data);
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const handleMinusQuantity = () => {
    setQuantity(quantity - 1 < 1 ? 1 : quantity - 1);
  };
  const handlePlusQuantity = () => {
    setQuantity(quantity + 1);
  };
  const handleAddToCart = (e) => {
    dispatch(
      addToCart({
        productId: location?.state?.data,
        quantity: quantity,
      })
    );
  };
  return (
    <>
      <NavBarDetails name={location?.state?.data?.name} />
      <div className="ProductDetails section_container">
        <div className="_body">
          <div className="_p_l">
            <Image
              src={`${API.Admin.Image}/${location?.state?.data?.image?.path}`}
              alt="Image"
              preview
              width="100"
            />
            {/* <img
              style={{
                borderRadius: "12px",
              }}
              src={`${API.Admin.Image}/${location?.state?.data?.image?.path}`}
              alt=" "
            /> */}
          </div>
          <div className="_p_r">
            <h3 className="title">{location?.state?.data?.name}</h3>
            <p className="pragraph">Category : {location?.state?.data?.category?.name}</p>
            <div className="information">
              <FloatLabel style={{ width: "100%" }}>
                <InputTextarea
                  autoResize
                  disabled
                  value={location?.state?.data?.description}
                  className="responsiveInput"
                  style={{ width: "100%", margin: "0", border: "none" }}
                  id="description"
                  type="text"
                />
              </FloatLabel>
            </div>
            <div className="clices">
              <button onClick={handlePlusQuantity}>+</button>
              <label>{quantity}</label>
              <button onClick={handleMinusQuantity}>-</button>
              <div className="button" onClick={(e) => handleAddToCart(e)}>
                <div className="button-wrapper">
                  <div className="text">Buy Now</div>
                  <span className="icon">
                    <svg
                      viewBox="0 0 16 16"
                      className="bi bi-cart2"
                      fill="currentColor"
                      height="16"
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
