import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <section className="footer section_container">
      Copyright © 2024 EMTOP Inc. All rights reserved.
    </section>
  );
};

export default Footer;
