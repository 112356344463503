import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import ProductDetails from "./Page/ProductDetails/ProductDetails";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" index element={<Layout />} />
        <Route path="ProductDetails" element={<ProductDetails />} />
      </Routes>
    </div>
  );
}

export default App;
