import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-scroll";
import "./Header.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../Context/Context";

const Header = () => {
  const { t, i18n } = useContext(AuthContext);
  const [showSide, setShowSide] = useState(false);
  const carts = useSelector((store) => store.cart.item);
  const [totalQuantity, setTotalQuantity] = useState(0);
  useEffect(() => {
    let total = 0;
    carts.forEach((item) => {
      total += item.quantity;
    });
    setTotalQuantity(total);
  }, [carts]);
  return (
    <div className="nav">
      <nav className={`${i18n.language === "ar" ? "ar" : ""}`}>
        <div className={`logo_menu ${i18n.language === "ar" ? "ar" : ""}`}>
          <div className="logo">
            <img
              src={require("../../assets/image/EMTOP-Syrian1.png")}
              alt="logo"
            />
          </div>
          <ul
            className={`menu ${showSide ? "active" : ""} ${
              i18n.language === "ar" ? "ar" : ""
            }`}
          >
            <li>
              <Link
                onClick={() => setShowSide(!showSide)}
                to="home"
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={-100}
                duration={800}
              >
                {t("menu.home")}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setShowSide(!showSide)}
                to="service"
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
              >
                {t("menu.service")}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setShowSide(!showSide)}
                to="products"
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
              >
                {t("menu.products")}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setShowSide(!showSide)}
                to="contact"
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
              >
                {t("menu.contact")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="search_store">
          <select
            onChange={() => {
              if (i18n.language === "en") {
                i18n.changeLanguage("ar");
              } else {
                i18n.changeLanguage("en");
              }
            }}
          >
            <option value="en">en</option>
            <option value="ar">ar</option>
          </select>
          {/* <i
            className="bx bx-sync"
            onClick={() => {
              if (i18n.language === "en") {
                i18n.changeLanguage("ar");
              } else {
                i18n.changeLanguage("en");
              }
            }}
          ></i> */}
          {/* <i className="bx bx-cart-alt"></i> */}
          {/* <span>{totalQuantity}</span> */}
        </div>
        <div className="menu_icon">
          <i
            className="bx bx-menu-alt-right menu_icon_right"
            onClick={() => {
              setShowSide(!showSide);
            }}
          />
        </div>
      </nav>
    </div>
  );
};

export default Header;
