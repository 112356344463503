import React, { useContext, useEffect, useState } from "react";
import "./Contact.css";
import { AuthContext } from "../../Context/Context";
import Toast from "../../Component/Sweetalert/Sweetalert";
import { API } from "../../Config/Api";
import axios from "axios";

const Contact = () => {
  const { t, i18n } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [dataCat, setDataCat] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        API.Category.v1_client_contact,
        {
          email: email,
          subject: subject,
          description: description,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          Toast.fire({
            text: `${res.data.message}`,
            icon: "success",
          });
          setEmail("");
          setSubject("");
          setDescription("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (i18n.language === "en") {
      axios
        .get(`${API.Category.v1_client_about}`, {
          headers: {
            "Accept-Language": "en",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          setDataCat(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${API.Category.v1_client_about}`, {
          headers: {
            "Accept-Language": "ar",
            Accept: "application/json",
          },
        })
        .then((res) => {
          setDataCat(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [i18n.language]);
  return (
    <section className="contact section_container">
      <div
        tyle={{
          gap: i18n.language === "ar" ? "0 20px" : "",
        }}
        className={`section_header ${i18n.language === "ar" ? "ar" : ""}`}
      >
        <h2
          style={{
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
          }}
        >
          {t("menu.contact")}
        </h2>
      </div>
      <div
        className="section_subheader"
        style={{
          letterSpacing: i18n.language === "ar" ? "0" : "",
          fontFamily: i18n.language === "ar" ? "Cairo" : "",
        }}
      >
        {t("menu.help_you_need")}
      </div>
      <div className={`contact_body ${i18n.language === "ar" ? "ar" : ""}`}>
        <div className="contact_b_l">
          <ul
            className="contact_icon"
            style={{
              padding: i18n.language === "ar" ? "10px 0 10px 20px" : "",
              direction: i18n.language === "ar" ? "rtl" : "",
            }}
          >
            <li>
              <i className="bx bxs-map"></i>
              <label>{dataCat?.address}</label>
            </li>
            <li>
              <i className="bx bx-phone"></i>
              <label>{dataCat?.phone_number}</label>
            </li>
            <li>
              <i className="bx bxl-gmail"></i>
              <label>{dataCat?.gmail}</label>
            </li>
          </ul>
          <div
            className="social_media"
            style={{
              justifyContent: i18n.language === "ar" ? "flex-end" : "",
            }}
          >
            <div className="children1">
              <a
                href={dataCat?.instagram}
                target="_blank"
                className="Btn Btn_insta"
              >
                <span className="svgContainer">
                  <i className="bx bxl-instagram svgIcon"></i>
                </span>
                <span className="BG"></span>
              </a>
            </div>
            <div className="children2">
              <a
                href={dataCat?.facebook}
                target="_blank"
                className="Btn Btn_face"
              >
                <span className="svgContainer">
                  <i className="bx bxl-facebook svgIcon"></i>
                </span>
                <span className="BG"></span>
              </a>
            </div>
          </div>
        </div>
        <div className="contact_b_r">
          <form>
            <div className="child_group">
              <input
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                placeholder="Your Email"
              />
              <input
                required
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                type="text"
                placeholder="Subjects"
              />
            </div>
            <div className="child_group">
              <textarea
                required
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                rows={8}
                type="text"
                placeholder="Description"
              />
            </div>
            <div className="child_group">
              <button onClick={handleSubmit}>Send</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
