import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  item: [],
};
const cartSLice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const { productId, quantity } = action.payload;
      const indexProductId = state.item.findIndex(
        (item) => item.productId === productId
      );
      if (indexProductId >= 0) {
        state.item[indexProductId].quantity += quantity;
      } else {
        state.item.push({ productId, quantity });
      }
    },
  },
});
export const { addToCart } = cartSLice.actions;
export default cartSLice.reducer;
