import React, { useContext } from "react";
import "./Service.css";
import { Link } from "react-scroll";
import { AuthContext } from "../../Context/Context";

const Service = () => {
  const { t, i18n } = useContext(AuthContext);
  return (
    <section className="service section_container">
      <div
        style={{
          gap: i18n.language === "ar" ? "0 20px" : "",
        }}
        className={`section_header ${i18n.language === "ar" ? "ar" : ""}`}
      >
        <img src={require("../../assets/image/icon-yy5.png")} alt="" />
        <h2
          style={{
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
          }}
        >
          {t("menu.service")}
        </h2>
      </div>
      <div
        className="section_subheader"
        style={{
          letterSpacing: i18n.language === "ar" ? "0" : "",
          fontFamily: i18n.language === "ar" ? "Cairo" : "",
        }}
      >
        {t("service.section_subheader")}
      </div>
      <div className={`service_body ${i18n.language === "ar" ? "ar" : ""}`}>
        <div className="service_b_l">
          <img src={require("../../assets/image/photo1.jpg")} alt="" />
          <img src={require("../../assets/image/photo2.jpg")} alt="" />
          <img src={require("../../assets/image/photo3.jpg")} alt="" />
          <img src={require("../../assets/image/photo5.jpg")} alt="" />
        </div>
        <div
          className="service_b_r"
          style={{
            textAlign: i18n.language === "ar" ? "end" : "",
          }}
        >
          <h3>
            <span>S</span>PARE <span>P</span>ARTS
          </h3>
          <p
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
            }}
          >
            {t("service.service_b_r")}
          </p>
          <Link
            to="products"
            className="learn_more"
            spy={true}
            smooth={true}
            offset={-60}
            duration={800}
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
            }}
          >
            {t("home_b_r.Read_more")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Service;
