import React from "react";
import "./NavBarDetails.css";
import { useNavigate } from "react-router-dom";

const NavBarDetails = ({ name }) => {
  const nav = useNavigate();
  return (
    <div className="Container_Nav">
      <div className="NavBarDetails">
        <div className="logo">
          <img
            src={require("../../assets/image/EMTOP-Syrian1.png")}
            alt="logo"
          />
        </div>
        <div className="Details">{name ?? ""}</div>
        <div
          className="GoToBack"
          onClick={() => {
            nav(-1);
          }}
        >
          <i className="bx bx-arrow-back"></i>
        </div>
      </div>
    </div>
  );
};

export default NavBarDetails;
