import React, { useContext } from "react";
import "./Home.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "swiper/css/effect-creative";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-scroll";
import { AuthContext } from "../../Context/Context";

const Home = () => {
  const { t, i18n } = useContext(AuthContext);
  const items = [
    {
      image: require("../../assets/image/ph-ya-1.png"),
      h2: "ECKL2007 Tools",
    },
    {
      image: require("../../assets/image/ph-ya-2.png"),
      h2: "ELAB20018 Tools",
    },
    {
      image: require("../../assets/image/ph-ya-3.png"),
      h2: "Tools",
    },
    {
      image: require("../../assets/image/ph-ya-4.png"),
      h2: "Tools",
    },
    {
      image: require("../../assets/image/ph-ya-5.png"),
      h2: "Tools",
    },
    {
      image: require("../../assets/image/ph-ya-6.png"),
      h2: "Tools",
    },
  ];
  const icons = [
    {
      icon: require("../../assets/image/icon-yy1.png"),
      title: "Test",
    },
    {
      icon: require("../../assets/image/icon-yy2.png"),
      title: "we",
    },
    {
      icon: require("../../assets/image/icon-yy3.png"),
      title: "3",
    },
    {
      icon: require("../../assets/image/icon-yy4.png"),
      title: "4",
    },
    {
      icon: require("../../assets/image/icon-yy5.png"),
      title: "5",
    },
    {
      icon: require("../../assets/image/icon-yy6.png"),
      title: "6",
    },
  ];
  return (
    <section
      className={`home section_container ${i18n.language === "ar" ? "ar" : ""}`}
    >
      <div className={`home_body ${i18n.language === "ar" ? "ar" : ""}`}>
        <div
          className="home_b_r"
          style={{
            flex: i18n.language === "ar" ? "0.5" : "",
            textAlign: i18n.language === "ar" ? "end" : "",
          }}
        >
          <h1
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
            }}
          >
            {t("home_b_r.h1")}
          </h1>
          <p
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
            }}
          >
            {t("home_b_r.p")}
          </p>
          <Link
            to="service"
            className="learn_more"
            spy={true}
            smooth={true}
            offset={-60}
            duration={800}
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
            }}
          >
            {t("home_b_r.Read_more")}
          </Link>
        </div>
        <div
          className="home_b_l"
          style={{
            flex: i18n.language === "ar" ? "0.4" : "",
          }}
        >
          <Swiper
            grabCursor={true}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            style={{
              right: i18n.language === "ar" ? "0" : "",
            }}
          >
            {items.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="item">
                    <img
                      style={{
                        width: "80%",
                        background: "var(--Pr-color)",
                      }}
                      src={item.image}
                      alt=""
                    />
                    <div className={`item_block`}>
                      <h2>{item.h2}</h2>
                      <Link
                        className="btn"
                        to="products"
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={800}
                      >
                        Watch
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div className={`home_footer ${i18n.language === "ar" ? "ar" : ""}`}>
        {icons.map((item, index) => {
          return (
            <div className="item_box">
              <Link
                to="products"
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
                key={index}
              >
                <img src={item.icon} alt="" />
                {/* <p>{item.title}</p> */}
                <i
                  style={{ fontSize: "20px", backgroundColor: "transparent" }}
                  className="bx bx-chevrons-down bx-fade-down"
                ></i>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Home;
