import React from "react";
import Header from "./Component/Header/Header";
import Home from "./Page/Home/Home";
import Service from "./Page/Service/Service";
import Products from "./Page/Products/Products";
import Contact from "./Page/Contact/Contact";
import Footer from "./Page/Footer/Footer";

const Layout = () => {
  return (
    <>
      <Header />
      <Home />
      {/* <section style={{height:"20vh"}}></section> */}
      <Service />
      <Products />
      <Contact />
      <Footer />
    </>
  );
};

export default Layout;
