import React, { useContext, useEffect, useState } from "react";
import "./Products.css";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../Store/cart";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/Context";
import axios from "axios";
import { API } from "../../Config/Api";
import { Paginator } from "primereact/paginator";

const Products = () => {
  const nav = useNavigate();
  const carts = useSelector((store) => store.cart.item);
  const { t, i18n } = useContext(AuthContext);
  const [activeItem, setActiveItem] = useState("");
  const dispatch = useDispatch();
  const [dataCat, setDataCat] = useState([]);
  const [product, setProduct] = useState([]);
  const handleAddToCart = (e, id) => {
    dispatch(
      addToCart({
        productId: id,
        quantity: 1,
      })
    );
  };
  useEffect(() => {
    if (i18n.language === "en") {
      axios
        .get(`${API.Category.v1_cms_category}?isPaginate=${0}`, {
          headers: {
            "Accept-Language": "en",
            Accept: "application/json",
          },
        })
        .then((res) => {
          setDataCat(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${API.Category.v1_cms_category}?isPaginate=${0}`, {
          headers: {
            "Accept-Language": "ar",
            Accept: "application/json",
          },
        })
        .then((res) => {
          setDataCat(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [i18n.language]);
  const pageSize = 6;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");
  const [item_id, setItem_id] = useState("");
  const onPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setFirst(event.first);
    setRows(event.rows);
    if (i18n.language === "en") {
      axios
        .get(
          `${
            API.Category.v1_client_product
          }/${item_id}?page=${currentPage}&size=${pageSize}&isPaginate=${1}`,
          {
            headers: {
              "Accept-Language": "en",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setProduct(res?.data?.data?.data);
          setTotalItems(res?.data?.data?.total);
          // setDataCat(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${
            API.Category.v1_client_product
          }/${item_id}?page=${currentPage}&size=${pageSize}&isPaginate=${1}`,
          {
            headers: {
              "Accept-Language": "ar",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setProduct(res?.data?.data?.data);
          setTotalItems(res?.data?.data?.total);
          // setDataCat(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <section className="products section_container">
      <div
        style={{
          gap: i18n.language === "ar" ? "0 20px" : "",
        }}
        className={`section_header ${i18n.language === "ar" ? "ar" : ""}`}
      >
        <img src={require("../../assets/image/icon-yy6.png")} alt="" />
        <h2
          style={{
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
          }}
        >
          {t("menu.products")}
        </h2>
      </div>
      <div
        className="products_body"
        style={{
          flexDirection: i18n.language === "ar" ? "row-reverse" : "",
        }}
      >
        <div
          className="products_p_l"
          style={{
            textAlign: i18n.language === "ar" ? "start" : "",
            direction: i18n.language === "ar" ? "rtl" : "",
          }}
        >
          <h3
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
            }}
          >
            {i18n.language === "ar" ? "نوع الفئة" : "Category Type"} :
          </h3>
          <div className="items_cat">
            {dataCat.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{}}
                  className={`item_cat ${
                    activeItem === item.name ? "active" : ""
                  }`}
                  onClick={() => {
                    setActiveItem(item.name);
                    setItem_id(item.id);
                    if (i18n.language === "en") {
                      axios
                        .get(
                          `${API.Category.v1_client_product}/${
                            item.id
                          }?page=${currentPage}&size=${pageSize}&isPaginate=${1}`,
                          {
                            headers: {
                              "Accept-Language": "en",
                              Accept: "application/json",
                            },
                          }
                        )
                        .then((res) => {
                          console.log(res.data);
                          setProduct(res?.data?.data?.data);
                          setTotalItems(res?.data?.data?.total);
                          // setDataCat(res.data.data);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    } else {
                      axios
                        .get(
                          `${API.Category.v1_client_product}/${
                            item.id
                          }?page=${currentPage}&size=${pageSize}&isPaginate=${1}`,
                          {
                            headers: {
                              "Accept-Language": "ar",
                              Accept: "application/json",
                            },
                          }
                        )
                        .then((res) => {
                          console.log(res.data);
                          setProduct(res?.data?.data?.data);
                          setTotalItems(res?.data?.data?.total);
                          // setDataCat(res.data.data);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="products_p_r">
          <h3
            style={{
              letterSpacing: i18n.language === "ar" ? "0" : "",
              textAlign: i18n.language === "ar" ? "end" : "",
            }}
          >
            {activeItem}
          </h3>
          <div className="det_pros">
            {product.map((item, index) => {
              return (
                <div key={index} className="det_pro">
                  <img
                    onClick={() => {
                      nav("/ProductDetails", {
                        state: {
                          data: item,
                        },
                      });
                    }}
                    src={`${API.Admin.Image}/${item.image.path}`}
                    alt=""
                  />
                  <h3
                    style={{
                      letterSpacing: i18n.language === "ar" ? "0" : "",
                    }}
                  >
                    {item.name}
                  </h3>
                  <div className="content">
                    <p>Price :{item.price}</p>
                    <div
                      className="button"
                      onClick={(e) => handleAddToCart(e, item.id)}
                    >
                      <div className="button-wrapper">
                        <div className="text">Buy Now</div>
                        <span className="icon">
                          <svg
                            viewBox="0 0 16 16"
                            className="bi bi-cart2"
                            fill="currentColor"
                            height="16"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {product.length === 0 && (
            <div
              style={{
                height: "460px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "50px",
                color: "var(--Pr-color)",
              }}
            >
              No Products
            </div>
          )}
          <div className="card">
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalItems}
              // rowsPerPageOptions={[10, 20, 30]}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
